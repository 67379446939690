<script>
import fabricObject from "./fabricObject";
export default {
  name: "FabricImageFromURL",
  mixins: [fabricObject],
  props: {
    id: {
      type: String,
      default: "id",
    },
    url: { type: String, default: "../vue.png" },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      image: null,
      type: "image",
      customWatch: ["url"],
    };
  },
  watch: {
    parentItem: {
      handler(newValue) {
        if (newValue) {
          //Parent is created
          this.createImage();
        }
      },
      immediate: true,
    },
    url() {
      if (this.parentItem) {
        if (this.image) {
          this.destroyImage();
        }
        this.createImage();
      }
    },
  },
  methods: {
    createImage() {
      this.$emit("url-loading");
      this.fabric.Image.fromURL(
        this.url,
        (img) => {
          this.image = img;
          this.$emit("image-loaded", img);
          const resize = !this.definedProps.top;
          if (this.parentType == "group") {
            this.parentItem.addWithUpdate(this.image);
          } else {
            if (resize) {
              let imgWidth = img.width;
              let imgHeight = img.height;
              let canvasWidth = this.canvas.getWidth();
              let canvasHeight = this.canvas.getHeight();

              let imgRatio = imgWidth / imgHeight;
              let canvasRatio = canvasWidth / canvasHeight;
              if (this.id === "bg-image") {
                if (imgRatio <= canvasRatio) {
                  if (imgHeight > canvasHeight) {
                    img.scaleToHeight(canvasHeight - 100);
                  }
                } else {
                  if (imgWidth > canvasWidth) {
                    img.scaleToWidth(canvasWidth - 100);
                  }
                }
              } else if (this.id.indexOf("image-") === 0) {
                if (imgRatio <= canvasRatio) {
                  if (imgHeight > canvasHeight) {
                    img.scaleToHeight(canvasHeight - 100);
                  }
                } else {
                  if (imgWidth > canvasWidth) {
                    img.scaleToWidth(canvasWidth - 100);
                  }
                }
              }
            }
            this.canvas.add(this.image);
            resize && this.image.viewportCenter();
          }
          this.createEvents();
          this.createWatchers();
          this.$emit("url-loaded");
        },
        { ...this.definedProps }
      );
    },
    destroyImage() {
      this.destroyEvents();
      if (this.parentType == "group") {
        if (this.group) {
          this.group.removeWithUpdate(this.image);
        }
      } else {
        if (this.canvas) {
          this.canvas.remove(this.image);
        }
        this.image = null;
      }
    },
  },
  render(h) {
    return this.$slots.default ? h("div", this.$slots.default) : undefined;
  },
};
</script>
