<script>
import fabricObject from "./fabricObject";
export default {
  name: "FabricCircle",
  mixins: [fabricObject],
  props: {
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    radius: {
      type: Number,
      default: 50,
    },
    fill: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      circle: null,
      type: "circle",
    };
  },
  watch: {
    parentItem: {
      handler(newValue) {
        if (newValue) {
          //Parent is created
          this.circle = new this.fabric.Circle({ ...this.definedProps });
          if (this.parentType == "group") {
            this.parentItem.addWithUpdate(this.circle);
          } else {
            this.canvas.add(this.circle);
          }
          if (!this.definedProps.top) {
            this.circle.viewportCenter();
          }
          this.createEvents();
          this.createWatchers();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {},
  methods: {},
  render(h) {
    return this.$slots.default ? h("div", this.$slots.default) : undefined;
  },
};
</script>
